.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.app-background {
  background-color: #FAF7F5; /* Earth-100 as base */
  background-image: url('../public/contour-lines.svg');
  background-repeat: repeat;
  background-size: 200px 200px; /* Adjust size as needed */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Style for Radix dialog boxes and dropdown lists */
[data-radix-dialog-content], 
.bg-background, 
[data-radix-popper-content-wrapper] > div[role="listbox"] {
  background: whitesmoke !important;
}

nav#headerbar {
  padding-left: 2rem;
}
